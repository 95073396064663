import React from 'react'
import { css } from 'linaria'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const AnnotationTopStyle = css`
  background: var(--color-background-bluegray50);

  & > .text {
    max-width: var(--width-max-content);
    margin-right: auto;
    margin-left: auto;
    padding-top: 16px;
    padding-right: 24px;
    padding-left: 24px;
    font-size: ${rem(10)};
    letter-spacing: 0;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      padding-top: 24px;
      font-size: ${rem(11)};
      text-align: center;
    }
  }
`

const AnnotationTopComponent = () => {
  return (
    <div className={AnnotationTopStyle}>
      <p className="text">
        ※
        2024年中に「BOXIL」上へ新たに投稿された口コミ評価の総得点が、リファレンスチェックサービス部門でもっとも高いサービスをスマートキャンプ株式会社が選出
      </p>
    </div>
  )
}

export default AnnotationTopComponent
