import React, { useEffect } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { css } from 'linaria'
import DownloadDocument from '~/components/Common/DownloadDocument'
import Layout from '~/components/Common/Layout'
import Appeal from '~/components/Top/Appeal'
import About from '~/components/Top/About'
import Advantage from '~/components/Top/Advantage'
import Annotation from '~/components/Top/Annotation'
import Case from '~/components/Top/Case'
import Function from '~/components/Top/Function'
import Faq from '~/components/Top/Faq'
import Hero from '~/components/Top/Hero'
import Popup from '~/components/Top/Popup'
import Partner from '~/components/Top/Partner'
import Price from '~/components/Top/Price'
import Reason from '~/components/Top/Reason'
import Support from '~/components/Top/Support'
import Thought from '~/components/Top/Thought'
import Topics from '~/components/Top/Topics'
import { STYLE } from '~/utils/constant'
import { setScrollAnimation } from '~/utils/helper'

const indexStyle = css`
  width: 100vw;
  overflow: hidden auto;

  & > .content {
    margin-top: 64px;
    padding-right: 24px;
    padding-left: 24px;

    @media screen and (${STYLE.MEDIA.PC}) {
      margin-top: 128px;
      padding-right: 32px;
      padding-left: 32px;
    }

    &.-about,
    &.-advantage {
      position: relative;
      margin-top: 0;
      padding-top: 56px;
      background: var(--color-background-bluegray50);
    }

    &.-advantage {
      padding-top: 64px;
      padding-bottom: 64px;

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-top: 128px;
        padding-bottom: 128px;
      }
    }

    &.-reason {
      position: relative;
      margin-top: 0;
      padding-top: 64px;

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-top: 128px;
      }
    }

    &.-function,
    &.-price,
    &.-topics {
      padding-top: 64px;
      padding-bottom: 64px;
      background: var(--color-background-bluegray50);

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-top: 128px;
        padding-bottom: 128px;
      }
    }

    &.-topics {
      margin-top: 0;

      @media screen and (${STYLE.MEDIA.PC}) {
        margin-top: 0;
      }
    }

    &.-appeal {
      position: relative;
      margin-top: 0;
      padding-top: 32px;
      padding-bottom: 48px;
      overflow: hidden;
      background: var(--color-gradient-key);

      @media screen and (${STYLE.MEDIA.PC}) {
        margin-top: 0;
        padding-top: 72px;
        padding-bottom: 64px;
      }
    }

    &.-case {
      padding-bottom: 64px;

      @media screen and (${STYLE.MEDIA.PC}) {
        padding-bottom: 128px;
      }
    }

    &.-thought {
      position: relative;
      overflow: hidden;
      background: var(--color-gradient-key);
    }
  }
`

const seo = {
  title: 'リファレンスチェックサービスなら',
}

const IndexPage = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    setScrollAnimation()
  }, [])

  return (
    <Layout seo={seo}>
      <main className={indexStyle}>
        <Hero />
        <Partner />
        <Annotation />
        <section className="content -about">
          <About />
        </section>
        <section id="advantage" className="content -advantage">
          <Advantage />
        </section>
        <section id="reason" className="content">
          <Reason />
        </section>
        <section id="function" className="content -function">
          <Function />
        </section>
        <div className="content -appeal">
          <Appeal />
        </div>
        <div id="support" className="content">
          <Support />
        </div>
        <div id="price" className="content -price">
          <Price />
        </div>
        <div id="case" className="content -case">
          <Case />
        </div>
        <div className="content -appeal">
          <Appeal />
        </div>
        <div id="topics" className="content -topics">
          <Topics />
        </div>
        <div id="faq" className="content">
          <Faq />
        </div>
        <div id="thought" className="content -thought">
          <Thought />
        </div>
        <div id="request" className="download">
          <DownloadDocument />
        </div>
        <Popup />
      </main>
    </Layout>
  )
}

export default IndexPage
