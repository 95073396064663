import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { css } from 'linaria'
import { rem } from '~/utils/helper'
import { STYLE } from '~/utils/constant'

const popupTopStyle = css`
  display: none;
  position: fixed;
  z-index: var(--zindex-menu);
  right: 24px;
  bottom: 24px;
  left: 24px;
  grid-template-areas:
    'image text'
    'image button';
  grid-template-columns: 104px 1fr;
  grid-template-rows: 1fr auto;
  padding: 12px 16px 12px 12px;
  column-gap: 12px;
  transform: translateY(8px);
  transition-property: display, opacity, transform;
  transition-duration: 0.24s;
  transition-timing-function: var(--transition-md);
  border: 2px solid var(--color-border-grey300);
  border-radius: 4px;
  opacity: 0;
  background-color: var(--color-background-bright);
  box-shadow: 0 5px 5px 0 #0000001f;
  box-shadow: 0 3px 14px 0 #0000000a;
  box-shadow: 0 8px 10px 0 #0000000f;
  row-gap: 4px;
  transition-behavior: allow-discrete;
  will-change: display, opacity, transform;

  @media screen and (${STYLE.MEDIA.TABLET}) {
    left: auto;
    grid-template-columns: 160px 1fr;
    padding: 16px 24px 16px 16px;
    column-gap: 16px;
  }

  &.is-show {
    display: grid;
    transform: translateY(0);
    opacity: 1;

    @starting-style {
      transform: translateY(8px);
      opacity: 0;
    }
  }

  & > .imagewrapper {
    display: block;
    position: relative;
    grid-area: image;
    width: 100%;
  }

  & > .text {
    grid-area: text;
    margin-top: -3px;
    padding-right: 24px;
    font-size: ${rem(11)};
    font-weight: 700;
    line-height: 1.4;
    white-space: nowrap;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      margin-top: -5px;
      font-size: ${rem(14)};
      line-height: 1.6;
    }
  }

  & > .text > .inline {
    display: inline;
    background: linear-gradient(transparent 70%, #ffd600 0%);
  }

  & > .button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    padding-right: 16px;
    padding-left: 16px;
    border: 1px solid transparent;
    border-radius: 100px;
    border-color: var(--color-border-key);
    background-color: var(--color-background-key);
    color: var(--color-text-bright);
    font-size: ${rem(11)};
    font-weight: 700;
    letter-spacing: 0;
    text-decoration: none;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      height: 40px;
      font-size: ${rem(14)};
    }
  }

  & > .close {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 24px;
    height: 24px;
    transition: var(--hover-button-transition);
    border: 0;
    background: transparent;
    cursor: pointer;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      top: 8px;
      right: 8px;
    }
  }

  & > .close > .icon {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 2px;
    margin: auto;
    border-radius: 2px;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      width: 20px;
      height: 2px;
      border-radius: 2px;
      background: var(--color-background-black);
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }
  }
`

const PopupTopComponent = () => {
  // 表示/非表示を管理するstate
  const [isShow, setIsShow] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsShow(true)
    }, 480)

    return () => clearTimeout(timer)
  }, [])

  const hideMenu = () => {
    setIsShow(false)
  }

  return (
    <div className={`${popupTopStyle} ${isShow ? 'is-show' : ''}`}>
      <Link className="imagewrapper" to="/resources/rc_complete_guide">
        <StaticImage
          className="image"
          objectFit="contain"
          src="../../images/popup_compendium.png"
          loading="eager"
          placeholder="none"
          alt="リファレンスチェック大全"
        />
      </Link>
      <p className="text">
        <span className="inline">
          この資料でリファレンスチェックの
          <br />
          全てがわかる！
        </span>
      </p>
      <Link className="button" to="/resources/rc_complete_guide">
        ダウンロードはこちら (無料)
      </Link>
      <button className="close" type="button" onClick={() => hideMenu()}>
        <i className="icon" />
      </button>
    </div>
  )
}

export default PopupTopComponent
